export default class AnalyticsQueryBuilder {
  constructor(parameters = {}) {
    parameters = this._clone(parameters);

    this._entities = parameters.entities || [];
    this._fields = parameters.fields || [];
    this._conditions = parameters.conditions || [];
    this._orderBy = parameters.orderBy || [];
    this._page = parameters.page || 1;
    this._limit = parameters.limit || 1000;
  }

  /**
   * @param theObject
   * @returns {any}
   * @private
   */
  _clone(theObject) {
    return JSON.parse(JSON.stringify(theObject));
  }

  /**
   * Adds primary entity
   *
   * @param entity
   * @param [alias]
   * @returns {AnalyticsQueryBuilder}
   */
  from(entity, alias = null) {
    return this._addEntity({ entity, alias });
  }

  /**
   * Adds a related entity
   * @param entity
   * @param relationship
   * @param [alias]
   * @returns {AnalyticsQueryBuilder}
   */
  with(entity, relationship, alias = null) {
    return this._addEntity({ entity, relationship, alias });
  }

  /**
   * Adds a limit per page
   * @param limit
   * @returns {AnalyticsQueryBuilder}
   */
  limit(limit) {
    this._limit = limit;
    return this;
  }

  /**
   * Specifies which page to query
   *
   * @param page
   * @returns {AnalyticsQueryBuilder}
   */
  page(page) {
    this._page = page;
    return this;
  }

  /**
   * Selects a field
   *
   * @param field
   * @param [alias]
   * @param {Object} [ifNull]
   * @returns {AnalyticsQueryBuilder}
   */
  select(field, alias = null, ifNull = null) {
    return this._addField({
      name: field,
      alias,
      ...this._prepareIfNull(ifNull),
    });
  }

  /**
   * Adds an aggregate field
   *
   * @param field
   * @param aggregator
   * @param [alias]
   * @param [orderBy]
   * @param {Object} [ifNull]
   * @returns {AnalyticsQueryBuilder}
   */
  aggregate(field, aggregator, alias = null, orderBy = null, ifNull = null) {
    const fieldObject = {
      name: field,
      aggregate: aggregator,
      alias,
      ...this._prepareIfNull(ifNull),
    };

    if (orderBy) {
      fieldObject.aggregate_order_by = orderBy;
    }

    return this._addField(fieldObject);
  }

  /**
   * Adds a field with a SUM aggregator
   *
   * @param field
   * @param [alias]
   * @returns AnalyticsQueryBuilder
   */
  sum(field, alias = null) {
    return this.aggregate(field, "SUM", alias);
  }

  /**
   * Adds a field with a COUNT aggregator
   *
   * @param field
   * @param [alias]
   * @returns AnalyticsQueryBuilder
   */
  count(field, alias = null) {
    return this.aggregate(field, "COUNT", alias);
  }

  /**
   * Adds a field with a MAX aggregator
   *
   * @param field
   * @param [alias]
   * @returns AnalyticsQueryBuilder
   */
  max(field, alias = null) {
    return this.aggregate(field, "MAX", alias);
  }

  /**
   * Adds a field with a MIN aggregator
   *
   * @param field
   * @param [alias]
   * @returns AnalyticsQueryBuilder
   */
  min(field, alias = null) {
    return this.aggregate(field, "MIN", alias);
  }

  /**
   * Adds a field with a groupBy
   *
   * @param field
   * @param {Object} [ifNull]
   * @returns AnalyticsQueryBuilder
   */
  groupBy(field, ifNull = null) {
    return this._addField({
      name: field,
      group_by: true,
      ...this._prepareIfNull(ifNull),
    });
  }

  /**
   * @param field
   * @param operator
   * @param [value]
   * @param {Object} [ifNull]
   * @returns {AnalyticsQueryBuilder}
   */
  where(field, operator, value, ifNull = null) {
    return this._where(field, operator, value, false, ifNull);
  }

  /**
   *
   * @param field
   * @param firstValue
   * @param secondValue
   * @param {Object} [ifNull]
   * @returns AnalyticsQueryBuilder
   */
  whereBetween(field, firstValue, secondValue, ifNull = null) {
    return this._where(
      field,
      "BETWEEN",
      [firstValue, secondValue],
      false,
      ifNull
    );
  }

  /**
   *
   * @param field
   * @param operator
   * @param value
   * @param {Object} [ifNull]
   * @returns AnalyticsQueryBuilder
   */
  whereFields(field, operator, value, ifNull = null) {
    return this._where(field, operator, value, true, ifNull);
  }

  whereFieldsBetween(field, firstValue, secondValue, ifNull = null) {
    return this._where(
      field,
      "BETWEEN",
      [firstValue, secondValue],
      true,
      ifNull
    );
  }

  whereIn(field, values, ifNull = null) {
    return this._where(field, "IN", values, false, ifNull);
  }

  whereNotIn(field, values, ifNull = null) {
    return this._where(field, "NOT_IN", values, false, ifNull);
  }

  orWhere(callback) {
    return this._addRecursiveCondition("OR", callback);
  }

  andWhere(callback) {
    return this._addRecursiveCondition("AND", callback);
  }

  notWhere(callback) {
    return this._addRecursiveCondition("NOT", callback);
  }

  /**
   * Sort by field in ascending order
   *
   * @param {String} field
   * @param {Object} [ifNull]
   * @returns AnalyticsQueryBuilder
   */
  orderBy(field, ifNull = null) {
    return this._addOrderBy(field, true, ifNull);
  }

  /**
   * Sort by field in descending order
   *
   * @param {String} field
   * @param {Object} [ifNull]
   * @returns AnalyticsQueryBuilder
   */
  orderByDesc(field, ifNull = null) {
    return this._addOrderBy(field, false, ifNull);
  }

  dump() {
    const payload = {
      entities: this._entities,
      fields: this._fields,
      conditions: this._conditions,
      order_by: this._orderBy,
    };

    console.debug("Analytics query", payload);
    console.debug(JSON.stringify(payload, null, 4));

    return this;
  }

  toObject() {
    return this._clone({
      entities: this._entities,
      fields: this._fields,
      conditions: this._conditions,
      orderBy: this._orderBy,
      page: this._page,
      limit: this._limit,
    });
  }

  /**
   * @returns {[]}
   */
  getConditions() {
    return this._conditions;
  }

  /**
   * @returns {*|number}
   */
  getLimit() {
    return this._limit;
  }

  /**
   * @returns {*|number}
   */
  getPage() {
    return this._page;
  }

  /**
   *
   * @param operator
   * @returns {null|*}
   * @private
   */
  _mapOperators(operator) {
    const mappedOperator = {
      ">=": "GTE",
      ">": "GT",
      "<=": "LTE",
      "<": "LT",
      "!=": "DIFF",
      "<>": "DIFF",
      "=": "EQUALS",
      BETWEEN: "BETWEEN",
      IN: "IN",
      NOT_IN: "NOT_IN",
    }[operator];

    if (!operator) {
      return null;
    }

    return mappedOperator;
  }

  /**
   * @param field
   * @param operator
   * @param parameters
   * @param [isComparingFields]
   * @param {Object} [ifNull]
   * @returns {AnalyticsQueryBuilder}
   * @private
   */
  _where(
    field,
    operator,
    parameters,
    isComparingFields = false,
    ifNull = null
  ) {
    const mappedOperator = this._mapOperators(operator);
    const comparisonType = isComparingFields ? "field" : "value";

    if (!mappedOperator) {
      parameters = operator;
      operator = "EQUALS";
    } else {
      operator = mappedOperator;
    }

    if (!Array.isArray(parameters)) {
      parameters = [parameters];
    }

    parameters = parameters.map((parameter) =>
      typeof parameter === "string" ? parameter : parameter.toString()
    );

    return this._addCondition(operator, [
      { field, ...this._prepareIfNull(ifNull) },
      ...parameters.map((parameter) => ({ [comparisonType]: parameter })),
    ]);
  }

  /**
   * @param operation
   * @param parameters
   * @param conditions
   * @returns {AnalyticsQueryBuilder}
   * @private
   */
  _addCondition(operation, parameters = [], conditions = []) {
    if (conditions.length) {
      this._conditions.push({ operation, conditions });
      return this;
    }

    this._conditions.push({ operation, parameters });
    return this;
  }

  /**
   * @param operation
   * @param callback
   * @returns {AnalyticsQueryBuilder}
   * @private
   */
  _addRecursiveCondition(operation, callback) {
    const queryBuilder = callback(new AnalyticsQueryBuilder());

    return this._addCondition(operation, [], queryBuilder.getConditions());
  }

  /**
   * @param {String} field
   * @param {Boolean} isAscending
   * @param {Object} [ifNull]
   * @returns {AnalyticsQueryBuilder}
   * @private
   */
  _addOrderBy(field, isAscending, ifNull = null) {
    this._orderBy.push({
      field,
      asc: isAscending,
      ...this._prepareIfNull(ifNull),
    });
    return this;
  }

  /**
   * @param {Object} entityObject
   * @returns {AnalyticsQueryBuilder}
   * @private
   */
  _addEntity(entityObject) {
    this._entities.push(entityObject);
    return this;
  }

  /**
   * @param {Object} fieldObject
   * @returns {AnalyticsQueryBuilder}
   * @private
   */
  _addField(fieldObject) {
    this._fields.push(fieldObject);
    return this;
  }

  /**
   * @param {Object} ifNull
   * @returns {Object}
   * @private
   */
  _prepareIfNull(ifNull) {
    return ifNull
      ? {
          if_null_fields: ifNull.fields || null,
          if_null_value: ifNull.value || null,
          if_null_type: ifNull.type || null,
        }
      : {};
  }
}
