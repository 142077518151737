import store from "../store";
import { MUTATE_LOCATIONS_FILTER } from "../constants";

export default class LocationObserver {
  constructor($optix) {
    this.$optix = $optix;
    this.getLocationsFromUrl();
    this.listenToLocationChanges();
  }

  static init($optix) {
    return new this($optix);
  }

  getLocationsFromUrl() {
    const locationIds = (this.$optix.env.get.locations || "").split(",");
    store.commit(MUTATE_LOCATIONS_FILTER, locationIds);
  }

  listenToLocationChanges() {
    this.$optix.canvas.addMessageListener(
      "LocationPickerChanged",
      "Location",
      function(event) {
        store.commit(MUTATE_LOCATIONS_FILTER, event.data.payload);
        store.dispatch("handleGlobalLocationPickerChange");
      },
      false
    );
  }
}
