<template>
  <div>
    <div>Page not found!</div>
    <div style="padding: 8px;"><o-btn @click="close">Close</o-btn></div>
  </div>
</template>

<script>
import ErrorMixin from "@/mixins/ErrorMixin";

export default {
  mixins: [ErrorMixin],
  async mounted() {
    await this.$optix.init();
    this.$optix.iAmReady();

    await this.trackError("Page not found", {
      href: window.location.href,
      userAgent: navigator.userAgent,
    });
  },
  methods: {
    close() {
      // Side panel
      this.$optix.canvas.canvasRawCommand("CanvasPop");

      // Canvas
      this.$optix.canvas.canvasRawCommand("close");

      // Old-school webview (api-v1)
      this.webviewCommand("close");

      // Top-level window that has a single history entry
      window.close();
    },
    webviewCommand(command) {
      const message = JSON.stringify({ command });
      const iOS = !!navigator.userAgent.match(/(iPad|iPhone|iPod)/g);

      if (iOS && window.webkit?.messageHandlers) {
        window.webkit.messageHandlers.optixWebviewCallback.postMessage(message);
      } else if (window.Android?.optixWebviewCallback) {
        window.Android.optixWebviewCallback(message);
      }
    },
  },
};
</script>
