export const utilityFunction = (callback) => {
  try {
    return callback();
  } catch (err) {
    // Error handling logic
    console.error("Utility Error:\n");
    console.error(err);

    // Propagate error for additional handling
    throw err;
  }
};
