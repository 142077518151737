export default [
  {
    path: "notifications/settings",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/notifications/NotificationSettings.vue"
      ),
    props: {
      isUser: true,
    },
  },
];
