export default [
  {
    path: "optix-billing",
    name: "optix-billing",
    component: () =>
      import(
        /* webpackChunkName: "billing" */ "@/views/billing/CurrentPlan.vue"
      ),
  },
  // {
  //   path: "optix-billing/signup",
  //   component: () =>
  //     import(/* webpackChunkName: "billing" */ "@/views/billing/Signup.vue"),
  // },
  {
    path: "optix-billing/confirm",
    name: "optix-billing/confirm",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/views/billing/Confirm.vue"),
  },
  {
    path: "optix-billing/billing",
    name: "optix-billing/billing",
    component: () =>
      import(
        /* webpackChunkName: "billing" */ "@/views/billing/OptixBilling.vue"
      ),
  },
  {
    path: "generic/message",
    component: () =>
      import(/* webpackChunkName: "generic" */ "@/views/generic/Message.vue"),
  },
  {
    path: "generic/custom-recurrence",
    component: () =>
      import(
        /* webpackChunkName: "generic" */ "@/views/generic/CustomRecurrence.vue"
      ),
  },
  {
    path: "collections",
    component: () =>
      import(
        /* webpackChunkName: "collections" */ "@/views/collections/CollectionList.vue"
      ),
  },
  {
    path: "collections/edit",
    component: () =>
      import(
        /* webpackChunkName: "collections" */ "@/views/collections/CollectionEdit.vue"
      ),
  },
  {
    path: "products",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/products/ProductList.vue"
      ),
  },
  {
    path: "products/edit",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/products/ProductEdit.vue"
      ),
  },
  {
    path: "products/reorder",
    component: () =>
      import(
        /* webpackChunkName: "generic" */ "@/views/generic/ObjectsReorder.vue"
      ),
    props: {
      reorderType: "PRODUCT",
    },
  },
  {
    path: "passes",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/products/PassList.vue"
      ),
  },
  {
    path: "passes/edit",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "@/views/products/PassEdit.vue"
      ),
  },
  {
    path: "passes/reorder",
    component: () =>
      import(
        /* webpackChunkName: "generic" */ "@/views/generic/ObjectsReorder.vue"
      ),
    props: {
      reorderType: "PASS",
    },
  },
  {
    path: "sales",
    component: () =>
      import(/* webpackChunkName: "sales" */ "@/views/sales/SaleList.vue"),
  },
  {
    path: "sales/view",
    component: () =>
      import(/* webpackChunkName: "sales" */ "@/views/sales/SaleView.vue"),
  },
  {
    path: "issues/view",
    component: () =>
      import(/* webpackChunkName: "issues" */ "@/views/issues/IssueView.vue"),
  },
  {
    path: "assignments/custom-restriction",
    component: () =>
      import(
        /* webpackChunkName: "assignments" */ "@/views/assignments/CustomRestriction.vue"
      ),
  },
  {
    path: "assignments/view",
    component: () =>
      import(
        /* webpackChunkName: "assignments" */ "@/views/assignments/AssignmentView.vue"
      ),
  },
  {
    path: "assignments/end",
    component: () =>
      import(
        /* webpackChunkName: "assignments" */ "@/views/assignments/AssignmentEnd.vue"
      ),
  },
  {
    path: "assignments/edit",
    component: () =>
      import(
        /* webpackChunkName: "assignments" */ "@/views/assignments/AssignmentEdit.vue"
      ),
  },
  {
    path: "assignments/new",
    component: () =>
      import(
        /* webpackChunkName: "assignments" */ "@/views/assignments/new/Wizard.vue"
      ),
    children: [
      {
        path: "/",
        name: "assignments.new.1.definition",
        component: () =>
          import(
            /* webpackChunkName: "assignments" */ "@/views/assignments/new/steps/1.Definition.vue"
          ),
      },
      {
        path: "/payment",
        name: "assignments.new.2.payment",
        component: () =>
          import(
            /* webpackChunkName: "assignments" */ "@/views/assignments/new/steps/2.Payment.vue"
          ),
      },
      {
        path: "/review",
        name: "assignments.new.3.review",
        component: () =>
          import(
            /* webpackChunkName: "assignments" */ "@/views/assignments/new/steps/3.Review.vue"
          ),
      },
    ],
  },
  {
    path: "bookings/conflicts",
    component: () =>
      import(
        /* webpackChunkName: "bookings" */ "@/views/bookings/BookingConflicts.vue"
      ),
  },
  {
    path: "bookings/price",
    component: () =>
      import(
        /* webpackChunkName: "bookings" */ "@/views/bookings/BookingEditPrice.vue"
      ),
  },
  {
    path: "bookings/edit",
    component: () =>
      import(
        /* webpackChunkName: "bookings" */ "@/views/bookings/BookingEdit.vue"
      ),
  },
  {
    path: "bookings/view",
    component: () =>
      import(
        /* webpackChunkName: "bookings" */ "@/views/bookings/BookingView.vue"
      ),
  },
  {
    path: "blocks/edit",
    component: () =>
      import(/* webpackChunkName: "blocks" */ "@/views/blocks/BlockEdit.vue"),
  },
  {
    path: "blocks/view",
    component: () =>
      import(/* webpackChunkName: "blocks" */ "@/views/blocks/BlockView.vue"),
  },
  {
    path: "schedule/calendar",
    alias: ["bookings/calendar"],
    component: () =>
      import(
        /* webpackChunkName: "bookings" */ "@/views/schedule/Calendar.vue"
      ),
  },
  {
    path: "schedule/list",
    component: () =>
      import(
        /* webpackChunkName: "bookings" */ "@/views/schedule/ScheduleList.vue"
      ),
  },
  {
    path: "schedule/conflicts",
    component: () =>
      import(
        /* webpackChunkName: "bookings" */ "@/views/schedule/Conflicts.vue"
      ),
  },
  {
    path: "account-panel",
    component: () =>
      import(
        /* webpackChunkName: "account" */ "@/views/accounts/PanelAccount.vue"
      ),
  },
  {
    path: "account-panel/reorder-analytics",
    component: () =>
      import(
        /* webpackChunkName: "account" */ "@/views/accounts/PanelAnalyticsReorder.vue"
      ),
  },
  {
    path: "accounts/new-payment-method",
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "@/views/accounts/PaymentMethodNew.vue"
      ),
  },
  {
    path: "accounts/payment-method",
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "@/views/accounts/PanelPaymentMethod.vue"
      ),
  },
  {
    path: "accounts/new-user",
    component: () =>
      import(/* webpackChunkName: "accounts" */ "@/views/accounts/UserNew.vue"),
  },
  {
    path: "accounts/bulk-edit",
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "@/views/accounts/AccountBulkEdit.vue"
      ),
  },
  {
    path: "accounts/change-type",
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "@/views/accounts/AccountBulkEdit.vue"
      ),
  },
  {
    path: "accounts/delete",
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "@/views/accounts/AccountDelete.vue"
      ),
  },
  {
    path: "accounts/notify",
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "@/views/accounts/AccountNotify.vue"
      ),
  },
  {
    path: "announcements",
    component: () =>
      import(
        /* webpackChunkName: "announcements" */ "@/views/announcements/AnnouncementList.vue"
      ),
  },
  {
    path: "announcements/edit",
    component: () =>
      import(
        /* webpackChunkName: "announcements" */ "@/views/announcements/AnnouncementEdit.vue"
      ),
  },
  {
    path: "announcements/view",
    component: () =>
      import(
        /* webpackChunkName: "announcements" */ "@/views/announcements/AnnouncementView.vue"
      ),
  },
  {
    path: "announcements/interactions",
    component: () =>
      import(
        /* webpackChunkName: "announcements" */ "@/views/announcements/InteractionsList.vue"
      ),
  },
  {
    path: "analytics",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/Analytics.vue"
      ),
  },
  {
    path: "analytics/total_revenue",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/revenue/GrossRevenueView.vue"
      ),
  },
  {
    path: "analytics/revenue_by_payment",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/revenue/RevenueByPaymentView.vue"
      ),
  },
  {
    path: "analytics/revenue_by_type",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/revenue/RevenueByTypeView.vue"
      ),
  },
  {
    path: "analytics/revenue_by_plan",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/revenue/RevenueByPlanView.vue"
      ),
  },
  {
    path: "analytics/revenue_by_location",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/revenue/RevenueByLocationView.vue"
      ),
  },
  {
    path: "analytics/revenue_growth",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/revenue/RevenueGrowthView.vue"
      ),
  },
  {
    path: "analytics/forecasted_plan_revenue",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/revenue/ForecastedPlanRevenueView.vue"
      ),
  },
  {
    path: "analytics/forecasted_plan_revenue_by_location",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/revenue/ForecastedPlanRevenueByLocationView.vue"
      ),
  },
  {
    path: "analytics/active_users",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/accounts/ActiveUsersView.vue"
      ),
  },
  {
    path: "analytics/number_of_accounts",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/accounts/NumberOfAccountsView.vue"
      ),
  },
  {
    path: "analytics/number_of_new_subscriptions",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/accounts/NumberOfNewSubscriptionsView.vue"
      ),
  },
  {
    path: "analytics/number_of_accounts_on_plans",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/accounts/NumberOfAccountsOnPlansView.vue"
      ),
  },
  {
    path: "analytics/account_growth",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/accounts/AccountGrowthView.vue"
      ),
  },
  {
    path: "analytics/plan_growth",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/accounts/PlanGrowthView.vue"
      ),
  },
  {
    path: "analytics/avg_duration_bookings",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/activity/AvgDurationOfBookingView.vue"
      ),
  },
  {
    path: "analytics/number_of_checkins",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/activity/NumberOfCheckinsView.vue"
      ),
  },
  {
    path: "analytics/popular_checkin_time",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/activity/PopularCheckinTimeView.vue"
      ),
  },
  {
    path: "analytics/number_of_hours_booked",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/resource/NumberOfHoursBookedView.vue"
      ),
  },
  {
    path: "analytics/resource_utilization_pct",
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ "@/views/analytics/resource/ResourceUtilizationView.vue"
      ),
  },
  {
    path: "calendars/locations",
    component: () =>
      import(
        /* webpackChunkName: "calendars" */ "@/views/calendars/LocationCalendarList.vue"
      ),
  },
  {
    path: "calendars/resources",
    component: () =>
      import(
        /* webpackChunkName: "calendars" */ "@/views/calendars/ResourceCalendarList.vue"
      ),
  },
  {
    path: "invoices",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/InvoiceList.vue"
      ),
  },
  {
    path: "invoices/payments",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/PaymentList.vue"
      ),
  },
  {
    path: "invoices/send",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/SendInvoice.vue"
      ),
  },
  {
    path: "invoices/void",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/VoidInvoice.vue"
      ),
  },
  {
    path: "invoices/unvoid",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/UnvoidInvoice.vue"
      ),
  },
  {
    path: "invoices/charge",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/ChargeInvoice.vue"
      ),
  },
  {
    path: "invoices/set-paid",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/SetPaidInvoice.vue"
      ),
  },
  {
    path: "invoices/bulk-send",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/BulkSendInvoice.vue"
      ),
  },
  {
    path: "invoices/bulk-void",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/BulkVoidInvoice.vue"
      ),
  },
  {
    path: "invoices/bulk-charge",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/BulkChargeInvoice.vue"
      ),
  },
  {
    path: "invoices/bulk-set-paid",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/BulkSetPaidInvoice.vue"
      ),
  },
  {
    path: "invoices/bulk-charge",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "../views/invoices/BulkChargeInvoice.vue"
      ),
  },
  {
    path: "invoices/canvas",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/InvoiceCanvas.vue"
      ),
  },
  {
    path: "invoice-items",
    component: () =>
      import(
        /* webpackChunkName: "invoice-items" */ "@/views/invoiceItems/InvoiceItemsList.vue"
      ),
  },
  {
    path: "invoice-items/void",
    component: () =>
      import(
        /* webpackChunkName: "invoice-items" */ "@/views/invoiceItems/VoidInvoiceItem.vue"
      ),
  },
  {
    path: "invoice-items/bulk-void",
    component: () =>
      import(
        /* webpackChunkName: "invoice-items" */ "@/views/invoiceItems/BulkVoidInvoiceItem.vue"
      ),
  },
  {
    path: "invoice-items/create",
    component: () =>
      import(
        /* webpackChunkName: "invoice-items" */ "@/views/invoiceItems/BulkInvoiceItemCreate.vue"
      ),
  },
  {
    path: "invoice-items/add-to-invoice",
    component: () =>
      import(
        /* webpackChunkName: "invoice-items" */ "@/views/invoiceItems/InvoiceItemEdit.vue"
      ),
  },
  {
    path: "organization/custom-terms",
    component: () =>
      import(
        /* webpackChunkName: "organization" */ "@/views/organization/CustomTerms.vue"
      ),
  },
  {
    path: "resources",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resources/ResourceList.vue"
      ),
  },
  {
    path: "resources/create",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resources/ResourceCreate.vue"
      ),
  },
  {
    path: "resources/edit",
    name: "resources.edit",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resources/ResourceEdit.vue"
      ),
  },
  {
    path: "resources/edit/booking_policy",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resources/ResourceBookingPolicyEdit.vue"
      ),
  },
  {
    path: "resources/bulk_edit",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resources/ResourceBulkEdit.vue"
      ),
  },
  {
    path: "resources/duplicate",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resources/ResourceDuplicate.vue"
      ),
  },
  {
    path: "resources/types",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resourceTypes/ResourceTypeList.vue"
      ),
  },
  {
    path: "resources/types/edit",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resourceTypes/ResourceTypeEdit.vue"
      ),
  },
  {
    path: "resources/type_group",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resources/ResourceTypeGroupEdit.vue"
      ),
  },
  {
    path: "resources/types/time_slots",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resourceTypes/ResourceTypeTimeSlots.vue"
      ),
  },
  {
    path: "resources/types/time_selection",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resourceTypes/ResourceTypeTimeSelection.vue"
      ),
  },
  {
    path: "resources/types/wizard",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resourceTypes/wizard/ResourceTypeWizard.vue"
      ),
    children: [
      {
        path: "/",
        name: "resource_types.wizard.1.name",
        component: () =>
          import(
            /* webpackChunkName: "resources" */ "@/views/resourceTypes/wizard/steps/1.Name.vue"
          ),
      },
      {
        path: "/list-style",
        name: "resource_types.wizard.2.list_style",
        component: () =>
          import(
            /* webpackChunkName: "resources" */ "@/views/resourceTypes/wizard/steps/2.ListStyle.vue"
          ),
      },
      {
        path: "/placement",
        name: "resource_types.wizard.3.placement",
        component: () =>
          import(
            /* webpackChunkName: "resources" */ "@/views/resourceTypes/wizard/steps/3.Placement.vue"
          ),
      },
      {
        path: "/time-selection",
        name: "resource_types.wizard.4.time_selection",
        component: () =>
          import(
            /* webpackChunkName: "resources" */ "@/views/resourceTypes/wizard/steps/4.TimeSelection.vue"
          ),
      },
    ],
  },
  {
    path: "access",
    component: () =>
      import(/* webpackChunkName: "access" */ "@/views/access/Access.vue"),
  },
  {
    path: "access-templates/edit",
    component: () =>
      import(
        /* webpackChunkName: "access" */ "@/views/plans/AccessTemplateEdit.vue"
      ),
  },
  {
    path: "allowance/edit",
    component: () =>
      import(
        /* webpackChunkName: "allowances" */ "@/views/allowance/AllowanceEdit.vue"
      ),
  },
  {
    path: "allowance/add",
    component: () =>
      import(
        /* webpackChunkName: "allowances" */ "@/views/allowance/AllowanceAdd.vue"
      ),
  },
  {
    path: "allowance/add-pass",
    component: () =>
      import(
        /* webpackChunkName: "allowances" */ "@/views/allowance/AllowanceAddPass.vue"
      ),
  },
  {
    path: "plans/plan-templates",
    component: () =>
      import(
        /* webpackChunkName: "plan-templates" */ "@/views/plans/PlanTemplates.vue"
      ),
  },
  {
    path: "plans/plan-templates/edit",
    component: () =>
      import(
        /* webpackChunkName: "plan-templates" */ "@/views/plans/PlanTemplateEdit.vue"
      ),
  },
  {
    path: "plans/plan-templates/reorder",
    component: () =>
      import(
        /* webpackChunkName: "generic" */ "@/views/generic/ObjectsReorder.vue"
      ),
    props: {
      reorderType: "PLAN_TEMPLATE",
    },
  },
  {
    path: "plans/list",
    component: () =>
      import(
        /* webpackChunkName: "account-plans" */ "@/views/plans/PlanList.vue"
      ),
  },
  {
    path: "plans/cancel",
    name: "plans.cancel",
    component: () =>
      import(
        /* webpackChunkName: "account-plans" */ "@/views/plans/PlanCancel.vue"
      ),
  },
  {
    path: "plans/new",
    name: "plans.new",
    component: () =>
      import(
        /* webpackChunkName: "account-plans" */ "@/views/plans/PlanCreate.vue"
      ),
  },
  {
    path: "plans/edit",
    name: "plans.edit",
    component: () =>
      import(
        /* webpackChunkName: "account-plans" */ "@/views/plans/PlanEdit.vue"
      ),
  },
  {
    path: "plans/bulk-edit",
    component: () =>
      import(
        /* webpackChunkName: "account-plans" */ "@/views/plans/PlanBulkEdit.vue"
      ),
  },
  {
    path: "plans/select-template",
    component: () =>
      import(
        /* webpackChunkName: "account-plans" */ "@/views/plans/PlanEditFromPlanTemplate.vue"
      ),
  },
  {
    path: "plans/view",
    component: () =>
      import(
        /* webpackChunkName: "account-plans" */ "@/views/plans/PlanView.vue"
      ),
  },
  {
    path: "stripe/import",
    component: () =>
      import(/* webpackChunkName: "stripe" */ "@/views/stripe/Import.vue"),
  },
  {
    path: "stripe/checkout-methods",
    component: () =>
      import(
        /* webpackChunkName: "stripe" */ "@/views/stripe/CheckoutMethods.vue"
      ),
  },
  {
    path: "stripe/additional-methods",
    component: () =>
      import(
        /* webpackChunkName: "stripe" */ "@/views/stripe/AdditionalMethods.vue"
      ),
  },
  {
    path: "inbox",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/messages/Inbox.vue"
      ),
  },
  {
    path: "inbox/attachments",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/AttachmentList.vue"
      ),
  },
  {
    path: "inbox/message-delete",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/messages/MessageDelete.vue"
      ),
  },
  {
    path: "inbox/conversation-panel",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/messages/ConversationPanel.vue"
      ),
  },
  {
    path: "inbox/promote-plus",
    component: () =>
      import(
        /* webpackChunkName: "billing" */ "@/views/inbox/promote/PromoteInboxPlusPanel.vue"
      ),
  },
  {
    path: "inbox/plus-billing",
    component: () =>
      import(
        /* webpackChunkName: "billing" */ "@/views/billing/InboxPlusBilling.vue"
      ),
  },
  {
    path: "tasks",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/tasks/TaskList.vue"
      ),
  },
  {
    path: "tasks/edit",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/tasks/TaskEdit.vue"
      ),
  },
  {
    path: "notifications",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/notifications/ActivityList.vue"
      ),
  },
  {
    path: "notifications/settings",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/notifications/NotificationSettings.vue"
      ),
  },
  {
    path: "notifications/settings/group",
    component: () =>
      import(
        /* webpackChunkName: "inbox" */ "@/views/inbox/notifications/NotificationGroupSettings.vue"
      ),
  },
  {
    path: "wla",
    component: () =>
      import(/* webpackChunkName: "wla" */ "@/views/wla/WLAIntro.vue"),
  },
  {
    path: "wla/builder",
    component: () =>
      import(/* webpackChunkName: "wla" */ "@/views/wla/WLABuilder.vue"),
  },
  {
    path: "wla/builder-cropper",
    component: () =>
      import(
        /* webpackChunkName: "wla" */ "@/views/wla/design/WLABuilderDesignCropper.vue"
      ),
  },
  {
    path: "wla/authorization",
    component: () =>
      import(/* webpackChunkName: "wla" */ "@/views/wla/WLAAuthorization.vue"),
  },
  {
    path: "settings/checkins/day-rate/edit",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/locations/CheckinDayRateEdit.vue"
      ),
  },
  {
    path: "settings/location-invoicing-overrides",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/locations/LocationInvoicingOverrides.vue"
      ),
  },
  {
    path: "settings/transaction-fees",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/features/TransactionFees.vue"
      ),
  },
  {
    path: "settings/qr-code",
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/settings/QRCodeEdit"),
  },
  {
    path: "settings/home-screen-buttons",
    component: () =>
      import(
        /* webpackChunkName: "generic" */ "@/views/generic/ObjectsReorder.vue"
      ),
    props: {
      reorderType: "APP_BUTTON",
    },
  },
  {
    path: "settings/home-screen-buttons/edit",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/mobileApp/HomeScreenButtonEdit.vue"
      ),
  },
  {
    path: "settings/more-screen-links",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/mobileApp/MoreScreenLinkList.vue"
      ),
  },
  {
    path: "settings/more-screen-links/edit",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/mobileApp/MoreScreenLinkEdit.vue"
      ),
  },
  {
    path: "settings/canvas",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/SettingsCanvas.vue"
      ),
  },
  {
    path: "settings/organization",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/OrganizationEdit.vue"
      ),
  },
  {
    path: "settings/locations",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/locations/LocationList.vue"
      ),
  },
  {
    path: "settings/locations/create",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/locations/LocationCreate.vue"
      ),
  },
  {
    path: "settings/locations/edit",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/locations/LocationEdit.vue"
      ),
  },
  {
    path: "settings/user-onboarding",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/users/UserOnboardingSettings.vue"
      ),
  },
  {
    path: "settings/user-communications",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/users/UserCommunicationsSettings.vue"
      ),
  },
  {
    path: "settings/confirm-silent-mode-change",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/users/ConfirmSilentModeChange.vue"
      ),
  },
  {
    path: "settings/all-features",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/features/FeaturesList.vue"
      ),
  },
  {
    path: "settings/invoicing",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/features/InvoicingSettings.vue"
      ),
  },
  {
    path: "settings/automations",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/automations/AutomationsSettings.vue"
      ),
  },
  {
    path: "settings/widget-overview",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/WidgetOverview.vue"
      ),
  },
  {
    path: "settings/email-account-edit",
    component: () =>
      import(
        /* webpackChunkName: "" */ "@/views/settings/EmailAccountEdit.vue"
      ),
  },
  {
    path: "cropper",
    component: () =>
      import(/* webpackChunkName: "" */ "@/views/CropperFromBroadcast.vue"),
  },
  {
    path: "helper-video",
    component: () =>
      import(/* webpackChunkName: "" */ "@/views/HelperVideo.vue"),
  },
  {
    path: "lightbox",
    component: () =>
      import(/* webpackChunkName: "" */ "@/components/LightBox.vue"),
  },
  {
    path: "workflows",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/WorkflowList.vue"
      ),
  },
  {
    path: "workflows/canvas",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/WorkflowEdit.vue"
      ),
  },
  {
    path: "workflows/view",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/WorkflowView.vue"
      ),
  },
  {
    path: "workflows/trigger",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/WorkflowTrigger.vue"
      ),
  },
  {
    path: "workflows/enrollment-list",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/WorkflowEnrollmentList.vue"
      ),
  },
  {
    path: "workflows/enrollment",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/WorkflowEnrollment.vue"
      ),
  },
  {
    path: "workflows/read",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/WorkflowPreview.vue"
      ),
  },
  {
    path: "workflows/recipes-list",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/recipes/RecipesList.vue"
      ),
  },
  {
    path: "workflows/recipe-view",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/recipes/RecipeView.vue"
      ),
  },
  {
    path: "workflows/replace-default-workflow-confirmation",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/workflows/recipes/ReplaceDefaultWorkflowConfirmation.vue"
      ),
  },
  {
    path: "workflows/billing",
    component: () =>
      import(
        /* webpackChunkName: "workflows" */ "@/views/billing/WorkflowBilling.vue"
      ),
  },
  {
    path: "workflows/promote-manual",
    component: () =>
      import(
        /* webpackChunkName: "" */ "@/views/workflows/promote/PromoteManual.vue"
      ),
  },
  {
    path: "checkins/edit",
    component: () =>
      import(/* webpackChunkName: "" */ "@/views/checkins/CheckinEdit.vue"),
  },
];
