import _groupBy from "lodash/groupBy";

export default class AnalyticsResponse {
  constructor(response) {
    this._errors = [];
    this._payload = [];
    this._parseResponse(response);
  }

  _parseResponse(response) {
    if (response.status !== 200) {
      this._errors = ["Requested failed"];
      return;
    }

    let payload = response.data;

    if (!payload.data?.analyticsQuery) {
      this._errors = ["Could not load data"];
      return;
    }

    if (payload.errors) {
      this._errors = payload.errors;
    }

    this._payload = payload.data.analyticsQuery;
  }

  _clone(theObject) {
    return JSON.parse(JSON.stringify(theObject));
  }

  isFlawed() {
    return !!this._errors.length;
  }

  isSuccessful() {
    return !this.isFlawed();
  }

  hasData() {
    return this.isSuccessful() && this.getData().length > 0;
  }

  getData() {
    return this._payload && this._clone(this._payload.data || []);
  }

  getColumns() {
    return this._payload.columns || [];
  }

  getColumn(atIndex) {
    return this.getColumns()[atIndex];
  }

  getTimezone() {
    return this._payload.timezone;
  }

  getCurrency() {
    return this._payload.currency;
  }

  getCurrencySymbol() {
    return this._payload.currency_symbol;
  }

  getCurrencyWithSymbol() {
    return this.getCurrencySymbol() + " " + this.getCurrency();
  }

  getColumnData(atColumn = 0, callback = (value) => value) {
    return this.getData().map((row) => callback(row[atColumn]));
  }

  groupByColumn(fromColumn) {
    return _groupBy(this.getData(), (series) => series[fromColumn]);
  }

  getSeriesAsFloat(fromColumn = 0) {
    return this.getColumnData(fromColumn, parseFloat);
  }

  getLastSeries() {
    return [...this.getData()].pop() || [];
  }

  getSeriesSum(fromColumn) {
    return this.getColumnData(fromColumn, parseFloat).reduce(
      (totalAmount, item) => parseFloat(item) + totalAmount,
      0
    );
  }

  getSeriesAverageByTwoColumns(valueColumn, counterColumn) {
    let counter = 0;
    let sum = 0;
    this.getData().map((line) => {
      counter += parseFloat(line[counterColumn]);
      sum += parseFloat(line[valueColumn]);
    });
    return counter > 0 ? sum / counter : 0;
  }

  hasNegativeValue(fromColumn = 0) {
    return this.getColumnData(fromColumn).some((dataPoint) => dataPoint < 0);
  }
}
