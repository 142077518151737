export default [
  {
    path: "index",
    component: () =>
      import(/* webpackChunkName: "docs" */ "@/views/docs/Index.vue"),
  },
  {
    path: "alerts",
    component: () =>
      import(/* webpackChunkName: "docs" */ "@/views/docs/Alerts.vue"),
  },
  {
    path: "buttons",
    component: () =>
      import(/* webpackChunkName: "docs" */ "@/views/docs/Buttons.vue"),
  },
  {
    path: "form-fields",
    component: () =>
      import(/* webpackChunkName: "docs" */ "@/views/docs/FormFields.vue"),
  },
  {
    path: "pickers",
    component: () =>
      import(/* webpackChunkName: "docs" */ "@/views/docs/Pickers.vue"),
  },
];
