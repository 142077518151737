let optixAdminBaseUrl = null;

export default {
  /**
   * Applies all optix base configuration for ui kits
   * @param {*} $optix
   */
  init($optix) {
    // Read URL variables
    $optix.env.readUrl();

    // Adding more configs to production environment
    $optix.env.setConf(
      {
        google_key: "<CREATE>",
        stripe_public_key: "pk_live_bYzwmSBY39wUDR9Q2rupqejH",
        spreedly_key: "77SlEJt07tR7XBDp8A7DvVfYNqU",
        feature_apps: {
          aec799d17fa560528c8cdaeff1ac5b3c1492d086: "Events",
          "569b0db389b9634ad9fb14f67b80c99f4d8cb2b7": "Perks",
        },
      },
      "production"
    );

    // By default staging urls are not added to the ui kit
    $optix.env.setConf(
      {
        optix_v1_url: "https://staging.catalufa.net/api",
        optix_v2_url: "https://api.catalufa.net",
        optix_admin_url: "https://SUBDOMAIN.staging.optixdev.com",
        google_key: "<CREATE>",
        spreedly_key: "DSms22xgi3tkaNI4Rgq6S6tMwVr",
        stripe_public_key: "pk_test_NgBDTokHSqjslkb1bOzCtLjK",
        feature_apps: {
          ad63cff09e6255d4a0c3154a3cab3c126b136fe5: "Events",
          d4878a70ab10dcf68a97c1aa4f678c02d45c5063: "Perks",
        },
      },
      "staging"
    );

    // By default local urls are not added to the ui kit
    $optix.env.setConf(
      {
        optix_v1_url: "http://sharedesk.local/api",
        optix_v2_url: "http://api.sharedesk.local",
        optix_admin_url: "http://SUBDOMAIN.optixapp.local",
        google_key: "AIzaSyCebuynPPjW3NaL3DCaj6MOHFLVNbTKrPM",
        spreedly_key: "DSms22xgi3tkaNI4Rgq6S6tMwVr",
        stripe_public_key: "pk_test_NgBDTokHSqjslkb1bOzCtLjK",
        feature_apps: {},
      },
      "local"
    );

    $optix.env.environment = process.env.VUE_APP_ENVIRONMENT;

    optixAdminBaseUrl = $optix.env
      .getConf("optix_admin_url")
      .replace(/SUBDOMAIN/, $optix.env.get.subdomain);
  },
  optixAdminUrl(path) {
    return optixAdminBaseUrl ? optixAdminBaseUrl + path : null;
  },
};
