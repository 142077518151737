import axios from "axios";

export default {
  methods: {
    trackError(error, details) {
      const apiUrl = this.$optix.env.getConf("optix_v1_url");

      return axios({
        method: "POST",
        url: apiUrl + "/logger/",
        data: {
          platform: "ui-components",
          error,
          details,
        },
      });
    },
  },
};
