import { DateTime } from "luxon";
import _isEqual from "lodash/isEqual";

export const SEVEN_DAYS = 1;
export const FOUR_WEEKS = 2;
export const THREE_MONTHS = 3;
export const SIX_MONTHS = 4;
export const TWELVE_MONTHS = 5;
export const MONTH_TO_DATE = 6;
export const QUARTER_TO_DATE = 7;
export const YEAR_TO_DATE = 8;

export const PAST = 1;
export const FUTURE = 2;

export const now = (timezone) => DateTime.local().setZone(timezone);
export const yesterday = (timezone) =>
  now(timezone)
    .minus({ days: 1 })
    .startOf("day");

export const getDateRanges = (timezone) => {
  return [
    {
      text: "Last 7 days",
      value: [SEVEN_DAYS, ...getLastSevenDaysRange(timezone)],
      type: PAST,
    },
    {
      text: "Last 4 weeks",
      value: [FOUR_WEEKS, ...getLastFourWeeksRange(timezone)],
      type: PAST,
    },
    {
      text: "Last 3 months",
      value: [THREE_MONTHS, ...getLastThreeMonthsRange(timezone)],
      type: PAST,
    },
    {
      text: "Last 12 months",
      value: [TWELVE_MONTHS, ...getLastTwelveMonthsRange(timezone)],
      type: PAST,
    },
    {
      text: "Month to date",
      value: [MONTH_TO_DATE, ...getMonthToDateRange(timezone)],
      type: PAST,
    },
    {
      text: "Quarter to date",
      value: [QUARTER_TO_DATE, ...getQuarterToDateRange(timezone)],
      type: PAST,
    },
    {
      text: "Year to date",
      value: [YEAR_TO_DATE, ...getYearToDateRange(timezone)],
      type: PAST,
    },
    // Future
    {
      text: "Next 7 days",
      value: [SEVEN_DAYS, ...getNextSevenDaysRange(timezone)],
      type: FUTURE,
    },
    {
      text: "Next 4 weeks",
      value: [FOUR_WEEKS, ...getNextFourWeeksRange(timezone)],
      type: FUTURE,
    },
    {
      text: "Next 3 months",
      value: [THREE_MONTHS, ...getNextThreeMonthsRange(timezone)],
      type: FUTURE,
    },
    {
      text: "Next 6 months",
      value: [SIX_MONTHS, ...getNextSixMonthsRange(timezone)],
      type: FUTURE,
    },
  ];
};

export const getDateRange = (timezone, range, type = PAST) =>
  (
    getDateRanges(timezone).find(
      (item) => item.value[0] === range && item.type === type
    ) || {}
  ).value;

export const getPastDateRanges = (timezone) =>
  getDateRanges(timezone).filter((item) => item.type === PAST);

export const getFutureDateRanges = (timezone) =>
  getDateRanges(timezone).filter((item) => item.type === FUTURE);

export const getLastSevenDaysRange = (timezone) => {
  return [
    now(timezone)
      .minus({ days: 7 })
      .startOf("day")
      .toISODate(),
    yesterday(timezone).toISODate(),
  ];
};

export const getLastFourWeeksRange = (timezone) => {
  return [
    now(timezone)
      .minus({ weeks: 4 })
      .toISODate(),
    yesterday(timezone).toISODate(),
  ];
};

export const getLastThreeMonthsRange = (timezone) => {
  return [
    now(timezone)
      .minus({ months: 3 })
      .startOf("month")
      .toISODate(),
    yesterday(timezone).toISODate(),
  ];
};

export const getLastTwelveMonthsRange = (timezone) => {
  return [
    now(timezone)
      .minus({ months: 12 })
      .startOf("month")
      .toISODate(),
    yesterday(timezone).toISODate(),
  ];
};

export const getMonthToDateRange = (timezone) => {
  return [
    now(timezone)
      .startOf("month")
      .toISODate(),
    yesterday(timezone).toISODate(),
  ];
};

export const getQuarterToDateRange = (timezone) => {
  return [
    now(timezone)
      .startOf("quarter")
      .toISODate(),
    yesterday(timezone).toISODate(),
  ];
};

export const getYearToDateRange = (timezone) => {
  return [
    now(timezone)
      .startOf("year")
      .toISODate(),
    yesterday(timezone).toISODate(),
  ];
};

export const getNextSevenDaysRange = (timezone) => {
  return [
    now(timezone).toISODate(),
    now(timezone)
      .plus({ days: 7 })
      .endOf("day")
      .toISODate(),
  ];
};

export const getNextFourWeeksRange = (timezone) => {
  return [
    now(timezone).toISODate(),
    now(timezone)
      .plus({ weeks: 4 })
      .toISODate(),
  ];
};

export const getNextThreeMonthsRange = (timezone) => {
  return [
    now(timezone).toISODate(),
    now(timezone)
      .plus({ months: 3 })
      .endOf("month")
      .toISODate(),
  ];
};

export const getNextSixMonthsRange = (timezone) => {
  return [
    now(timezone).toISODate(),
    now(timezone)
      .plus({ months: 6 })
      .endOf("month")
      .toISODate(),
  ];
};

export const getRangeDescription = (timezone, range) => {
  let foundRange = getDateRanges(timezone).find((item) =>
    _isEqual(item.value, range)
  );

  if (foundRange) {
    return foundRange.text;
  }

  let startAt = DateTime.fromISO(range[1]).toLocaleString(DateTime.DATE_MED);
  let endAt = DateTime.fromISO(range[2]).toLocaleString(DateTime.DATE_MED);

  return `${startAt} - ${endAt}`;
};
