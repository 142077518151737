import { extend } from "vee-validate";
import {
  required,
  max,
  between,
  min_value,
  max_value,
  numeric,
  integer,
} from "vee-validate/dist/rules";
import ColorContrastChecker from "color-contrast-checker";
import Validate from "optixapp-ui-kit/src/vue/mixins/Validate";
import Vue from "vue";
import { MAX_TAG_LENGTH } from "@/constants";
import { ValidationProvider, ValidationObserver } from "vee-validate";

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);

extend("required", {
  ...required,
  message: "This field is required",
});

extend("after_equals_date", {
  validate: (value, [otherValue]) => {
    return {
      valid: otherValue <= value,
    };
  },
  message: "Please enter a valid date",
});

extend("max", {
  ...max,
  message: "You have exceeded the number of characters allowed",
});

extend("min_value", {
  ...min_value,
  message: "Invalid value",
});
extend("max_value", {
  ...max_value,
  message: "Invalid value",
});

extend("numeric", {
  ...numeric,
  message: "Please enter a valid number",
});

extend("integer", {
  ...integer,
  message: "Please enter a valid number",
});

extend("decimal", {
  validate: (value, { decimals = "*", separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );

    return {
      valid: regex.test(value),
    };
  },
  message: "Please enter a valid number",
});

/**
 * Validator to count decimal point
 * ex: decimal:4 or decimal:2
 */

/*
  extend("decimal", {
    validate: (value, { decimals = "*", separator = "." } = {}) => {
      if (value === null || value === undefined || value === "") {
        return {
          valid: false,
        };
      }
      if (Number(decimals) === 0) {
        return {
          valid: /^-?\d*$/.test(value),
        };
      }
      const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
      const regex = new RegExp(
        `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
      );

      return {
        valid: regex.test(value),
      };
    },
    params: ["decimals", "separator"],
    message: "Please enter a valid number with up to {decimals} decimal places",
  });
*/

extend("between", {
  ...between,
  message: "The number provided is out of range",
});

extend("truthy", {
  validate: (value) => !!value,
  message: "This field is required",
});

extend(
  "greaterThan",
  {
    message: "Maximum should be greater than minimum",
    validate: (value, [otherValue]) => {
      let otv = parseInt(otherValue);
      return (
        otherValue == "undefined" || otv == 0 || value == 0 || value >= otv
      );
    },
  },
  {
    hasTarget: true,
  }
);

extend(
  "greaterOrEqualThan",
  {
    message: "Maximum should be greater or equal to minimum",
    validate: (value, [otherValue]) => {
      return (
        otherValue == "undefined" || otherValue == "" || value >= otherValue
      );
    },
  },
  {
    hasTarget: true,
  }
);

extend("tags", function(value) {
  if (!Array.isArray(value)) {
    return false;
  }

  for (let i = 0; i < value.length; i++) {
    if ((value[i] || "").length > MAX_TAG_LENGTH) {
      return `Maximum length is ${MAX_TAG_LENGTH}`;
    }
  }

  return true;
});

extend("required_account", {
  validate: (value) => !!value?.account_id,
  message: "This field is required",
});

extend("contrast", {
  validate: (value, [ratio]) => {
    return new ColorContrastChecker().isLevelCustom(
      value,
      "#FFFFFF",
      parseFloat(ratio)
    );
  },
  message: "This color is too light to use, text will be illegible",
});

/** @see https://github.com/logaretm/vee-validate/blob/main/packages/rules/src/url.ts */
extend("url", {
  validate: (value) => {
    if (!value) {
      return true;
    } else if (!value.toLowerCase().match(/^https?:\/\//)) {
      return false;
    }
    try {
      return !!new URL(value);
    } catch {
      return false;
    }
  },
  message: "Please enter a valid URL",
});

/** @see https://github.com/logaretm/vee-validate/blob/main/packages/rules/src/email.ts */
extend("email", {
  validate: (value) => {
    if (!value) {
      return true;
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value));
  },
  message: "Please enter a valid email",
});

extend("all_emails", {
  validate(value) {
    if (!Array.isArray(value)) {
      return false;
    }
    return value.every((item) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(item));
      // return email.validate(item).valid;
    });
  },
  message: "All items must be valid email addresses",
});

extend("phone", {
  validate: (value) => value && !Validate.rules.phone(value),
  message: "Please enter a valid phone number",
});

extend("safe_from_xss", {
  validate: (value) => {
    const regex = /^[^<>"]*$/;
    return regex.test(value);
  },
  message: 'Contains invalid characters: <, >, or "',
});

export const getFirstInvalidInput = (validationObserverReference) => {
  return Object.entries(validationObserverReference.refs)
    .map(([key, value]) => ({
      key,
      value,
    }))
    .filter((error) => {
      if (!error || !error.value || !error.value.failedRules) {
        return false;
      }

      return Object.keys(error.value.failedRules).length > 0;
    })
    .shift();
};

export const scrollToError = (validationObserverReference) => {
  const lastInvalidInput = getFirstInvalidInput(validationObserverReference);

  if (lastInvalidInput) {
    validationObserverReference.refs[lastInvalidInput.key].$el.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};
