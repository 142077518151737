import Vue from "vue";

Vue.directive("visible-on-screen", {
  bind: function(el) {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1, // Adjust the threshold as needed
    };

    const observer = new IntersectionObserver(([entry]) => {
      el.dispatchEvent(
        new CustomEvent("visible", { detail: entry.isIntersecting })
      );
    }, options);

    observer.observe(el);

    el._vueIntersectionObserver = observer;
  },
  unbind: function(el) {
    if (el._vueIntersectionObserver) {
      el._vueIntersectionObserver.disconnect();
      delete el._vueIntersectionObserver;
    }
  },
});
