<template>
  <div>
    Something Went Wrong
  </div>
</template>

<script>
export default {
  name: "GenericError",
};
</script>
