import { MUTATE_LOCATIONS_FILTER } from "../../constants";

export default {
  namespaced: false,
  state: {
    locations: [],
  },
  mutations: {
    [MUTATE_LOCATIONS_FILTER]: (state, value) => {
      state.locations = value;
    },
  },
  getters: {
    getGlobalLocationFilter: (state) => state.locations,
  },
};
