import AnalyticsQueryBuilder from "@/utils/AnalyticsQueryBuilder";
import AnalyticsResponse from "@/utils/AnalyticsResponse";
import AnalyticsService from "@/services/AnalyticsService";
import Vue from "vue";
import { FUTURE, getDateRange, PAST } from "@/utils/DateRange";
import { getStorage } from "@/utils/Storage";

import {
  CUSTOM_DATERANGE,
  LS_ANALYTICS_DATE_RANGE,
  LS_ANALYTICS_FREQUENCY,
  LS_ANALYTICS_FUTURE_DATE_RANGE,
  LS_PRIMARY_LOCATIONS,
  MUTATE_ANALYTICS_STORE,
  MUTATE_DATE_RANGE,
  MUTATE_DEMO,
  MUTATE_FREQUENCY,
  MUTATE_FUTURE_DATE_RANGE,
  MUTATE_INIT_ANALYTICS_STORE,
  MUTATE_PRIMARY_LOCATIONS,
} from "@/constants";

export default {
  namespaced: false,
  state: {
    isShowingDemo: false,
    frequency: null,
    dateRange: null,
    futureDateRange: null,
    primaryLocations: [],
  },
  mutations: {
    [MUTATE_ANALYTICS_STORE]: (state, [store, key, value]) => {
      Vue.set(state[store], key, value);
    },
    [MUTATE_INIT_ANALYTICS_STORE]: (state, { store, parameters }) => {
      Vue.set(state, store, parameters);
    },
    [MUTATE_DEMO]: (state, isShowing) => {
      state.isShowingDemo = isShowing;
    },
    [MUTATE_FREQUENCY]: async (state, { frequency, persist }) => {
      state.frequency = frequency;

      if (persist) {
        try {
          await getStorage().setItem(LS_ANALYTICS_FREQUENCY, frequency);
        } catch (e) {
          console.error("Could not save frequency in local storage");
        }
      }
    },
    [MUTATE_DATE_RANGE]: async (state, { dateRange, persist }) => {
      state.dateRange = dateRange;

      if (persist) {
        try {
          await getStorage().setItem(
            LS_ANALYTICS_DATE_RANGE,
            JSON.stringify(dateRange)
          );
        } catch (e) {
          console.error("Could not save date range in local storage");
        }
      }
    },
    [MUTATE_FUTURE_DATE_RANGE]: async (state, { dateRange, persist }) => {
      state.futureDateRange = dateRange;

      if (persist) {
        try {
          await getStorage().setItem(
            LS_ANALYTICS_FUTURE_DATE_RANGE,
            JSON.stringify(dateRange)
          );
        } catch (e) {
          console.error("Could not save future date range in local storage");
        }
      }
    },
    [MUTATE_PRIMARY_LOCATIONS]: async (
      state,
      { primaryLocations, persist }
    ) => {
      state.primaryLocations = primaryLocations;

      if (persist) {
        try {
          await getStorage().setItem(
            LS_PRIMARY_LOCATIONS,
            JSON.stringify(primaryLocations)
          );
        } catch (e) {
          console.error("Could not save primary locations in local storage");
        }
      }
    },
  },
  getters: {
    hasStore: (state) => (store) => !!state[store],
    /**
     * @returns {AnalyticsQueryBuilder}
     */
    getQueryBuilder: (state) => (store) => {
      if (!state[store]) {
        return null;
      }

      return new AnalyticsQueryBuilder({ ...state[store].query });
    },
    getResponse: (state) => (store) => {
      if (state[store] && state[store].response) {
        return new AnalyticsResponse(state[store].response);
      }

      return null;
    },
    isChartLoading: (state) => (store) => {
      return state[store] && !!state[store].isLoading;
    },
    isRendered: (state) => (store) => {
      return state[store] && state[store].isRendered;
    },
    getDateRange: (state) => state.dateRange,
    getFrequency: (state) => state.frequency,
    isShowingDemo: (state) => state.isShowingDemo,
  },
  actions: {
    handleGlobalLocationPickerChange({ commit }) {
      commit(MUTATE_PRIMARY_LOCATIONS, {
        primaryLocations: [],
        persist: true,
      });
    },
    async restoreAnalyticsFilters({ commit }, timezone) {
      try {
        if (await getStorage().getItem(LS_ANALYTICS_FREQUENCY)) {
          commit(MUTATE_FREQUENCY, {
            frequency: await getStorage().getItem(LS_ANALYTICS_FREQUENCY),
            persist: false,
          });
        }

        if (await getStorage().getItem(LS_PRIMARY_LOCATIONS)) {
          commit(MUTATE_PRIMARY_LOCATIONS, {
            primaryLocations: JSON.parse(
              await getStorage().getItem(LS_PRIMARY_LOCATIONS)
            ),
            persist: false,
          });
        }

        if (await getStorage().getItem(LS_ANALYTICS_DATE_RANGE)) {
          let dateRange = JSON.parse(
            await getStorage().getItem(LS_ANALYTICS_DATE_RANGE)
          );

          if (dateRange[0] !== CUSTOM_DATERANGE) {
            // Update date range to reflect current date
            dateRange = getDateRange(timezone, dateRange[0], PAST);
          }

          commit(MUTATE_DATE_RANGE, {
            dateRange: dateRange,
            persist: false,
          });
        }

        if (await getStorage().getItem(LS_ANALYTICS_FUTURE_DATE_RANGE)) {
          let dateRange = JSON.parse(
            await getStorage().getItem(LS_ANALYTICS_FUTURE_DATE_RANGE)
          );

          if (dateRange[0] !== CUSTOM_DATERANGE) {
            // Update date range to reflect current date
            dateRange = getDateRange(timezone, dateRange[0], FUTURE);
          }

          commit(MUTATE_FUTURE_DATE_RANGE, {
            dateRange: dateRange,
            persist: false,
          });
        }
      } catch (e) {
        console.error("Could not restoreAnalyticsFilters from localStorage");
      }
    },
    initStore({ commit }, { store, parameters }) {
      commit(MUTATE_INIT_ANALYTICS_STORE, {
        store,
        parameters,
        isLoading: false,
        isRendered: false,
        response: null,
      });
    },
    async fetchAnalytics(
      { commit, getters },
      { store, callback = (query) => query }
    ) {
      await commit(MUTATE_ANALYTICS_STORE, [store, "isLoading", true]);
      await commit(MUTATE_ANALYTICS_STORE, [store, "isRendered", false]);

      const analyticsService = new AnalyticsService(this._vm.$optix);

      // Instantiate a new query builder with default query
      // Callback allows client to modify query
      let queryBuilder = getters.getQueryBuilder(store);
      queryBuilder = callback(queryBuilder);
      let limit = queryBuilder.getLimit();

      // Retrieve the query from queryBuilder and fetch the analytics
      let response = await analyticsService.query(queryBuilder.toObject());

      // Needs to fetch more pages
      // @TODO Use FetchPaged instead?
      if (
        !response.errors &&
        !response.data.errors &&
        getters.isShowingDemo === false &&
        response.data.data.analyticsQuery.total > limit
      ) {
        let responsePayload = response.data.data.analyticsQuery;
        let extraFetches = Math.ceil((responsePayload.total - limit) / limit);

        for (let currentPage = 1; currentPage <= extraFetches; currentPage++) {
          let extraPage = await analyticsService.query(
            queryBuilder.page(currentPage + 1).toObject()
          );

          responsePayload.data = responsePayload.data.concat(
            extraPage.data.data.analyticsQuery.data
          );
        }
      }

      await commit(MUTATE_ANALYTICS_STORE, [store, "response", response]);
      await commit(MUTATE_ANALYTICS_STORE, [store, "isLoading", false]);
    },
    async setAsRendered({ commit }, store) {
      await commit(MUTATE_ANALYTICS_STORE, [store, "isRendered", true]);
    },
    async toggleDemo({ commit }, isVisible) {
      commit(MUTATE_DEMO, isVisible);
    },
  },
};
