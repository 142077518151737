import Vue from "vue";

Vue.directive("max-characters", {
  bind: function(el, binding) {
    let maxCharacters = parseInt(binding.expression);
    el.addEventListener("keyup", (e) => {
      if (e.target.value.length >= maxCharacters) {
        e.target.value = e.target.value.substr(0, maxCharacters);
        e.target.dispatchEvent(new Event("input", { bubbles: true }));
      }
    });
  },
});
