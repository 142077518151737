<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Environment from "./utils/Environment";
import LocationObserver from "./utils/LocationObserver";
import { initStorage } from "./utils/Storage";

export default {
  name: "App",
  created() {
    this.$optix.canvas.startHandlingParentMessages();
    Environment.init(this.$optix);
    LocationObserver.init(this.$optix);
    initStorage(this.$optix.env.get.organization_id);
    this.$gtag.config({
      linker: {
        accept_incoming: true,
        domains: ["optixapp.com", "optixdev.com", "optixapp.local"],
      },
    });
  },
};
</script>

<style lang="scss"></style>
