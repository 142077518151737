import {
  MUTATE_WIZARD_BREADCRUMB,
  MUTATE_WIZARD_RESOURCE_TYPE,
  MUTATE_WIZARD_LOADING,
  MUTATE_WIZARD_META,
  MUTATE_WIZARD_PROGRESS,
  MUTATE_WIZARD_APP_LINK,
  LOCATION_SERVICE_HOURS,
} from "@/constants";

export default {
  namespaced: false,
  state: {
    // Wizard common properties
    breadcrumb: "Add resource type",
    isLoading: false,
    progress: 0,
    meta: {
      isAvailableInApp: true,
      isCreatingAppLink: false,
      isUsingTimeSlots: false,
      organization: null,
      availableAppLinks: [],
    },
    resourceType: {
      name: null,
      booking_experience: "GALLERY",
      time_selection_type: "FREE_SELECT",
      conditional_availability: [
        {
          account_type_label: null,
          use_custom_service_hours: false,
          time_within_week_days: [],
          mode: LOCATION_SERVICE_HOURS,
        },
      ],
      show_next_available: false,
      has_nonspecific_bookings: false,
    },
    appLink: {
      app_link_id: null,
      name: null,
      icon: null,
      position: "MOBILE_HOME_PRIMARY",
    },
  },
  mutations: {
    [MUTATE_WIZARD_BREADCRUMB]: (state, breadcrumb) => {
      state.breadcrumb = breadcrumb;
    },
    [MUTATE_WIZARD_LOADING]: (state, value) => {
      state.isLoading = value;
    },
    [MUTATE_WIZARD_PROGRESS]: (state, value) => {
      state.progress = value;
    },
    [MUTATE_WIZARD_RESOURCE_TYPE]: (state, data) => {
      state.resourceType = {
        ...state.resourceType,
        ...data,
      };
    },
    [MUTATE_WIZARD_APP_LINK]: (state, data) => {
      state.appLink = {
        ...state.appLink,
        ...data,
      };
    },
    [MUTATE_WIZARD_META]: (state, data) => {
      state.meta = {
        ...state.meta,
        ...data,
      };
    },
  },
  getters: {
    getProgress: (state) => state.progress,
    getIsLoading: (state) => state.isLoading,
    getResourceType: (state) => state.resourceType,
    getAppLink: (state) => state.appLink,
    getOrganization: (state) => state.meta.organization,
    isCreatingAppLink: (state) => state.meta.isCreatingAppLink,
    getContainerOptions: (state) => ({
      breadcrumb: [state.breadcrumb],
      loading: state.isLoading,
      headerStyle: {
        "padding-bottom": "24px !important",
      },
    }),
  },
  actions: {
    async setProgress({ commit }, value) {
      commit(MUTATE_WIZARD_PROGRESS, value);
    },
    async setBreadcrumbs({ commit }, value) {
      commit(MUTATE_WIZARD_PROGRESS, value);
    },
    async setLoading({ commit }, value) {
      commit(MUTATE_WIZARD_LOADING, value);
    },
  },
};
