import Vue from "vue";
import VueRouter from "vue-router";
import LoadingComponent from "@/views/errors/LoadingComponent";
import GenericError from "@/views/errors/GenericError";
import NotFound from "@/views/errors/NotFound";

import adminRoutes from "./adminRoutes";
import docsRoutes from "./docsRoutes";
import userRoutes from "./userRoutes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/admin",
    component: () => ({
      component: import("../layouts/Admin.vue"),
      loading: LoadingComponent,
      error: GenericError,
      delay: 0,
      timeout: 3000,
    }),
    children: adminRoutes,
  },
  {
    path: "/docs",
    component: () => ({
      component: import("../layouts/Admin.vue"),
      loading: LoadingComponent,
      error: GenericError,
      delay: 0,
      timeout: 3000,
    }),
    children: docsRoutes,
  },
  {
    path: "/user",
    component: () => ({
      component: import("../layouts/Admin.vue"),
      loading: LoadingComponent,
      error: GenericError,
      delay: 0,
      timeout: 3000,
    }),
    children: userRoutes,
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
