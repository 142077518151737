import * as localforage from "localforage";
import { utilityFunction } from "./Wrapper";

const storage = {};

export const getStorage = (name = "default") =>
  utilityFunction(() => {
    return storage[name];
  });

export const createStorage = (options) =>
  utilityFunction(() => {
    return localforage.createInstance(options);
  });

export const removeStorage = (options) =>
  utilityFunction(() => {
    return localforage.dropInstance(options);
  });

export const initStorage = (organizationId) =>
  utilityFunction(() => {
    if (organizationId) {
      storage.default = localforage.createInstance({
        name: `organization_${organizationId}`,
      });
    } else {
      storage.default = localforage.createInstance({ name: "optix" });
    }
  });
